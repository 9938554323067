import RavenWebManager from '@root/ui/RavenWebSDK'
import { formatEpochTime, getCookie, isEmpty } from './genralUtils'
import { isUserSignedIn, convertDate } from '@root/ui/utils'
import { checkPlatform } from './datalayer'
import { getFormattedDate } from '@root/ui/utils/dateHelper'
import { getInsuranceData } from './itinerary/addons'
import { checkIfAnyAncilliarySelected } from '@components/Addons/AddonsUtils'
import { dayDiff } from './itinerary/clevertap/clevertapHelper'
import dayjs from 'dayjs'
import logError from './logError'
import { getCurrentAppVersion } from './Ultra'
import { isFkEnv } from '@root/configs/Whitelabel'
import { formatDateStandardized } from './date/dateUtils'

export const stringifyPayload = (payload: any) => {
    const keys = Object.keys(payload)
    keys.forEach(key => {
        if (
            key === 'a_fare_price' ||
            key === 'a_ct_discount' ||
            key === 'supercoin_balance' ||
            key === 'supercoin_earned' ||
            key === 'supercoin_burnt' ||
            key === 'wallet_balance_used' ||
            key === 'convenience_fee'
        )
            payload[key] = Number(payload[key])
        else {
            payload[key] = '' + payload[key]
            payload[key] = payload[key].toLowerCase()
        }
    })
    return payload
}

export const stealDealsRavenTag = {
    BIG_BARGAIN: 'big_bargain',
    AIRLINE_EXCLUSIVE: 'airline_exclusives_coupon',
    TOP_DESTINATIONS: 'top_destination',
    CT_CURATED: 'cleartrip_curated',
    LAST_MINUTE_LUCK: 'last_minute',
    POPULAR_GEMS: 'popular_gems',
    WEEKEND_SPECIALS: 'weekend_specials',
    LONG_WEEKEND_SPECIALS:'long_weekend_specials'
}

export const stealDealsTag={
    BIG_BARGAIN: 'Big Bargain',
    AIRLINE_EXCLUSIVE: 'Airline Exclusives Coupon',
    TOP_DESTINATIONS: 'Top Destination',
    CT_CURATED: 'Cleartrip Curated',
    LAST_MINUTE_LUCK: 'Last Minute',
    POPULAR_GEMS: 'Popular Gems',
    WEEKEND_SPECIALS: 'Weekend Specials',
    LONG_WEEKEND_SPECIALS:'Long Weekend Specials'
}

export const extraRavenPayload = (itineraryData: any) => {
    const {
        REWARDS = [],
        COUPON: { appliedCouponDetails = {} } = {},
        ANCILLARY = {},
        INSURANCES = {}
    } = itineraryData || {}
    const rewardData = REWARDS?.filter((reward: any) => {
        return reward.rewardType === 'SUPERCOINS'
    })?.[0]?.rewardsData
    const insuranceData = getInsuranceData(INSURANCES)
    const extraPayload = {
        a_smb_booked:
            checkIfAnyAncilliarySelected(ANCILLARY, 'SEAT', insuranceData) ||
            checkIfAnyAncilliarySelected(ANCILLARY, 'MEAL', insuranceData) ||
            checkIfAnyAncilliarySelected(ANCILLARY, 'BAGGAGE', insuranceData)
                ? 'yes'
                : 'no',
        a_tb_insurance_booked: checkIfAnyAncilliarySelected(ANCILLARY, 'INSURANCE', insuranceData) ? 'yes' : 'no',
        ct_coupon_applied: appliedCouponDetails?.couponData?.[0]?.couponCode || '',
        supercoin_burnt: rewardData?.superCoinDetail?.balanceDetails?.redeemableSuperCoinAmount || 0,
        supercoin_earned: rewardData?.superCoinDetail?.earnConfigDetails?.maxEarnCoins || 0
    }
    return extraPayload
}

export const travellersErrorPayload = (itineraryData: any, isCfwUser: boolean = false) => {
    const {
        SEARCH_DETAILS = {},
        FLIGHT: { flights = {} } = {},
        FARE_CARDS: { fareCards = {} } = {}
    } = itineraryData || {}

    const depDate = flights?.[0]?.segments?.[0]?.departDateTime || ''
    const adultCount = SEARCH_DETAILS?.paxInfo?.adults || 0
    const childCount = SEARCH_DETAILS?.paxInfo?.children || 0
    const infantCount = SEARCH_DETAILS?.paxInfo?.infants || 0
    const returnDate = flights?.[1]?.segments?.[0]?.departDateTime || ''

    const ravenPayload = {
        page_name: 'flights_travellers',
        a_fare_type: SEARCH_DETAILS?.fareType || 'N/A',
        a_return_date: returnDate ? getFormattedDate(returnDate, 'DD/MM/YYYY', 'YYYY/MM/DD') : 'N/A',
        a_dx: dayDiff(
            new Date(getFormattedDate(flights?.[0]?.segments?.[0]?.departDateTime || '', 'MM/DD/YYYY', 'YYYY/MM/DD')),
            new Date()
        ),
        a_adult_count: adultCount,
        a_child_count: childCount,
        a_infant_count: infantCount,
        a_origin: flights?.[0]?.departureAirportCode || 'NA',
        a_search_id: SEARCH_DETAILS?.searchId || 'N/A',
        a_departure_date: depDate ? getFormattedDate(depDate, 'DD/MM/YYYY', 'YYYY/MM/DD') : 'N/A',
        is_cfw_user: isCfwUser ? 'Yes' : 'No'
    }

    return ravenPayload
}

export const superCoinPayload = (itineraryData: any) => {
    const {REWARDS = []} = itineraryData || {}
    const rewardData = REWARDS?.filter((reward: any) => {
        return reward.rewardType === 'SUPERCOINS'
    })?.[0]?.rewardsData
    const payload = {
        supercoin_account_status : rewardData?.superCoinDetail?.status || 'N/A',
        supercoin_balance : rewardData?.superCoinDetail?.balanceDetails?.superCoinBalance || 0,
    }
    return payload
}

export const ravenSDKTrigger = (eventName: string, ravenPayload: any) => {
    const commonPayload = {
        platform: checkPlatform().toLowerCase(),
        login_status: isUserSignedIn() ? 'yes' : 'no',
        domain: window.location.host,
        vertical: 'air',
        fk_app_version : isFkEnv ? getCurrentAppVersion(navigator.userAgent) : 'N/A'
    }
    const newRavenPayload = stringifyPayload(ravenPayload)
    RavenWebManager.event(eventName, { ...newRavenPayload, ...commonPayload })
}
export const batchRavenEvent = (eventName:string, ravenPayload:object) => {
    if (typeof window !== 'undefined' && !!window.requestIdleCallback) {
        requestIdleCallback(() => {
            ravenSDKTrigger(eventName, ravenPayload);
        });
    } else {
        setTimeout(() => {
            ravenSDKTrigger(eventName, ravenPayload);
        }, 0);
    }
}
export const ravenSDKItinTrigger = (eventName: string, itineraryData: any, additionpayload: any) => {
    const {
        SEARCH_DETAILS = {},
        FLIGHT: { flights = {} } = {},
        FARE_CARDS: { fareCards = {} } = {},
        BANNERS : {MULTI_LOYALTY = {}} = {}
    } = itineraryData || {}
    const flightNumbers: any[] = []
    const airlineNames: any[] = []
    let itineraryId = ''
    if (typeof window !== 'undefined') {
        const locationArray = window?.location?.pathname?.split('/') || []
        itineraryId = locationArray?.[locationArray?.length - 2] || ''
    }
    const {programDetails = []} = MULTI_LOYALTY || {}
    if (!isEmpty(flights)) {
        flights.forEach((flight: { segments: any[] }) =>
            flight.segments.forEach(segment => {
                flightNumbers.push(segment.airline + '-' + segment.flightNumber)
                airlineNames.push(segment.airlineName)
            })
        )
        const selectedFareCard = fareCards?.filter?.((fareCard: any) => {
            return fareCard.selected === true
        })?.[0]
        let flightNumbersValue = flightNumbers.length > 1 ? flightNumbers.join(',') : flightNumbers[0]
        let airlineNameValue = airlineNames.length > 1 ? airlineNames.join(',') : airlineNames[0]
        const commonItinPayload = {
            a_trip_type: SEARCH_DETAILS?.sectorType === 'DOM' ? 'domestic' : 'international',
            a_journey_type: SEARCH_DETAILS?.journeyType === 'ONE_WAY' ? 'ow' : 'rt',
            a_origin: flights?.[0]?.departureAirportCode || 'NA',
            a_itinerary_id: itineraryId,
            a_destination: flights?.[0]?.arrivalAirportCode || 'NA',
            a_airline: airlineNameValue,
            a_flight_number: flightNumbersValue,
            a_ff_fare_type: selectedFareCard?.fareCardMessage?.title || 'N/A',
            is_loyalty_eligible : isEmpty(programDetails) ? 'no' : 'yes',
            loyalty_fare_type : programDetails?.[0]?.programName || 'N/A' ,
            isAmendment: window.location.pathname == '/flights/amendments' ? true : false
        }
        let finalPayload = { ...commonItinPayload }
        if (
            eventName === 'a_continue_payment_click' ||
            eventName === 'a_itinerary_continue_click' ||
            eventName === 'a_traveller_continue_clicked' ||
            eventName === 'a_add_on_continue_click'
        ) {
            finalPayload = { ...finalPayload, ...extraRavenPayload(itineraryData) }
        }
        if (eventName === 'a_itinerary_pageload' || eventName === 'a_itinerary_continue_click') {
            finalPayload = { ...finalPayload, ...superCoinPayload(itineraryData) }
        }

        ravenSDKTrigger(eventName, { ...additionpayload, ...finalPayload })
    }
}

