import { Container, Typography } from '@ct-design/lib'
import OfferIcon from './OfferIcon'

const CouponBar = ({ merchandisingData = [], setCouponBarLink = {} }) => {
    const containerStyle = {
        display: 'flex',
        gap: '1rem', // This is the equivalent of `flex-gap-3` (3 represents 1rem in Tailwind)
        overflowX: 'auto', // Enables horizontal scrolling
        scrollbarWidth: 'none', // For Firefox (hides scrollbar)
        msOverflowStyle: 'none', // For Internet Explorer (hides scrollbar)
        paddingTop:'8px',
        paddingBottom:'8px',
        paddingLeft:'16px',
        paddingRight:'16px'
      };
      const itemstyles = {
        paddingTop: '0.5rem',    // py-2: Padding on the top and bottom (2 * 0.25rem)
        paddingBottom: '0.5rem', 
        paddingLeft: '0.75rem',  // px-3: Padding on the left and right (3 * 0.25rem)
        paddingRight: '0.75rem',
        display: 'flex',         // flex: Sets the display to flexbox
        alignItems: 'center',    // flex-middle: Align items in the center vertically
        flexShrink: 0,           // flex-no-shrink: Prevents the item from shrinking
        borderRadius: '0.375rem' // br-6: Border radius of 6px (6 * 0.0625rem = 0.375rem)
      };
    return (
        <>
            {merchandisingData?.length ? (
                merchandisingData?.length > 1 ? (
                    <Container style={containerStyle}>
                        {merchandisingData?.map((item, index) => {
                            return (
                                <div key={item.DESC1}>
                                    <Container
                                        backgroundColor="#ECF8F4"
                                        width="280px"
                                        height="48px"
                                        style={itemstyles}
                                        onClick={() => {
                                            setCouponBarLink(item.LINK1)
                                        }}
                                    >
                                        <Container height="24px" width="24px">
                                            <OfferIcon />
                                        </Container>
                                        {item.DESC1.length > 90 && (
                                            <Typography variant="B3" className="ml-4">
                                                {item.DESC1.substring(0, 90) + '...'}
                                            </Typography>
                                        )}
                                        {item.DESC1.length <= 90 && (
                                            <Typography variant="B3" className="ml-4">
                                                {item.DESC1}
                                            </Typography>
                                        )}
                                    </Container>
                                </div>
                            )
                        })}
                    </Container>
                ) : (
                    <>
                        <Container style={containerStyle}>
                            <Container
                                backgroundColor="#ECF8F4"
                                width="100%"
                                height="48px"
                                style={itemstyles}
                                onClick={() => {
                                    setCouponBarLink(merchandisingData[0]?.LINK1)
                                }}
                            >
                                <Container height="24px" width="24px">
                                    <OfferIcon />
                                </Container>
                                {merchandisingData[0]?.DESC1.length > 90 && (
                                    <Typography variant="B3" className="ml-4">
                                        {merchandisingData[0]?.DESC1.substring(0, 90) + '...'}
                                    </Typography>
                                )}
                                {merchandisingData[0]?.DESC1.length <= 90 && (
                                    <Typography variant="B3" className="ml-4">
                                        {merchandisingData[0]?.DESC1}
                                    </Typography>
                                )}
                            </Container>
                        </Container>
                    </>
                )
            ) : null}
        </>
    )
}

export default CouponBar
