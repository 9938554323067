import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#11A670"
        stroke="#11A670"
        d="M11.345 3.569a1 1 0 011.31 0l.934.81a1 1 0 00.845.226l1.214-.235a1 1 0 011.135.656l.404 1.168a1 1 0 00.619.62l1.168.403a1 1 0 01.655 1.135l-.234 1.214a1 1 0 00.226.845l.81.934a1 1 0 010 1.31l-.81.934a1 1 0 00-.226.845l.235 1.214a1 1 0 01-.656 1.135l-1.168.404a1 1 0 00-.62.619l-.403 1.168a1 1 0 01-1.135.655l-1.214-.234a1 1 0 00-.845.226l-.934.81a1 1 0 01-1.31 0l-.934-.81a1 1 0 00-.845-.226l-1.214.235a1 1 0 01-1.135-.656l-.404-1.168a1 1 0 00-.619-.62l-1.168-.403a1 1 0 01-.655-1.135l.234-1.214a1 1 0 00-.226-.845l-.81-.934a1 1 0 010-1.31l.81-.934a1 1 0 00.226-.845L4.37 8.352a1 1 0 01.656-1.135l1.168-.404a1 1 0 00.62-.619l.403-1.168a1 1 0 011.135-.655l1.214.234a1 1 0 00.845-.226l.934-.81z"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.6 8.4l-7.2 7.2"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9.343 9.445h.025M14.743 14.846h.025"
      ></path>
    </svg>
  );
}

export default Icon;
