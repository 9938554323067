import logError from '@utils/logError'
import { isServer } from '@utils/appAgent'
const { path } = require("./utils")

class RavenWebSDK {

    event = (ravenEventName, masterObject = {}) => {
        try {
            if(path(['ravenWebManager'], window)) {
                window.ravenWebManager.triggerRaven(ravenEventName, masterObject)
            } else {
                if(path(['ravenEarlyEventQueue'], window)) {
                    window.ravenEarlyEventQueue.push({
                        name: ravenEventName,
                        payload: masterObject
                    })
                } else if (!isServer()) {
                    window.ravenEarlyEventQueue = []
                    window.ravenEarlyEventQueue.push({
                        name: ravenEventName,
                        payload: masterObject
                    })
                }
                console.log('Raven: Error in sending data to Raven, SDK not initialized. Event : ', ravenEventName);
            }
        } catch (error) {
            console.log('Raven: Error in sending data to Raven', error);
            logError(error)
        }
    }
}
const RavenWebManager = new RavenWebSDK()
export default RavenWebManager